export default {
  text: '#FFF',
  primary: '#feda88',
  secondary: '#050707',
  brandGrey: '#566464',
  background: '#421015',
  backgroundSecondary: '#311013',
  light: '#FFF',
  dark: '#020303'
}
