export default {
  heading: 'Lobster, serif',
  subheading: 'Montserrat, sans-serif',
  body: 'Roboto, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Handlee, cursive',
  googleFonts: [
    'Lobster: 400',
    'Roboto:100,200,300,400,500,600,700,800,900',
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Handlee'
  ]
  // customFamilies: ['Amelie', 'Corinthian Light', 'Futura'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/amelie/amelie.css',
  //   'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
  //   'https://www.gonation.biz/fonts/futura/futura.css'
  // ]
}
